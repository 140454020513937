

/* lato-300 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/lato-v22-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/lato-v22-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/lato-v22-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/lato-v22-latin-300.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/lato-v22-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/lato-v22-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-regular - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/lato-v22-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/lato-v22-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/lato-v22-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/lato-v22-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/lato-v22-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/lato-v22-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-italic - latin */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: url('./assets/fonts/lato-v22-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/lato-v22-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/lato-v22-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/lato-v22-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/lato-v22-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/lato-v22-latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-700 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/lato-v22-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/lato-v22-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/lato-v22-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/lato-v22-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/lato-v22-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/lato-v22-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-900italic - latin */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 900;
    src: url('./assets/fonts/lato-v22-latin-900italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/lato-v22-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/lato-v22-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/lato-v22-latin-900italic.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/lato-v22-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/lato-v22-latin-900italic.svg#Lato') format('svg'); /* Legacy iOS */
  }
  
  
  .font-regular-20{
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 700;
  }
  .font-bold-20{
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 700;
  }
  
  .font-bold-20{
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 900;
  }
  .font-bold-21{
    font-family: 'Lato';
    font-weight: 400;
    font-size: 20px;
  }
  
  .font-bold-28{
    font-family: 'Lato';
    font-weight: 500;
    font-size: 28px;
  }
  .font-bold-14{
    font-family: 'Lato';
    font-weight: 500;
    font-size: 14px;
  }
  .font-bold-18{
    font-family: 'Lato';
    font-weight: 600;
    font-size: 18px;
  }
.font-regular-16{
  font-family: 'Lato';
  font-weight: 700;
  font-size: 16px;
}
.font-bold-16{
  font-family: 'Lato';
  font-weight: 600;
  font-size: 16px;
}
.font-semi-bold-16{
  font-family: 'Lato';
  font-weight: 600;
  font-size: 16px;
}
.font-regular-14{
  font-family: 'Lato';
  font-weight: 400;
  font-size: 14px;
}
.font-regular-16{
  font-family: 'Lato';
  font-weight: 400;
  font-size: 16px;
}
.font-bold-16{
  font-family: 'Lato';
  font-weight: 500;
  font-size: 16px;
}
.font-bold-20{
  font-size: 20px;
  font-family: 'Lato';
  font-weight: 700;
}
.font-semi-bold-12{
  font-size: 12px;
  font-family: 'Lato';
  font-weight: 600;
}
.font-extra-bold-15{
  font-size: 15px;
  font-family: 'Lato';
  font-weight: 800;
}