
.userName {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #353a3d;
}
.notificationBadge {
  position: absolute;
  height: 20px;
  width: 20px;
  transform: translate(61%, -46%);
  border-radius: 50%;
  background-color: #eb5757;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-bottom: 6px;
  margin-bottom: 1px;
  color: #353a3d;
}
.title_auto{
  color: #000;
  font-size: 20px;
  margin: 0px;
  font-weight: 600;
}
.notification-count{
  position: absolute;
  transform: translate(61%, -46%);
  font-family: 'Lato';
  margin-left: 10px;
  margin-top: -1px;
  color: white;
  font-weight: 600;
  font-size: 10px;
}
.profile_pic1{
  height: 50px;
  width: 50px;
  border-radius: 50px;
}