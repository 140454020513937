.authLayout {
  background-color: #f5f7fb;
  min-height: 100vh;
  // height: 100%;
  // object-fit: cover;
}
.main-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1312px;
}
.makeStyles-drawer-2{
  width: 220px !important
}
